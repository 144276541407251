/* See External References - http://grgichtran.com/code/js/jqueryrotate.min.js */
/*
Lightbox v2.6
by Lokesh Dhakar - http://www.lokeshdhakar.com

For more information, visit:
http://lokeshdhakar.com/projects/lightbox2/

Licensed under the Creative Commons Attribution 2.5 License - http://creativecommons.org/licenses/by/2.5/
- free for use in both personal and commercial projects
- attribution requires leaving author name, author link, and the license info intact

Exemple Markup:
<a href="http://grgichtran.com/code/img/car-seat.jpg" data-lightbox="property"><img src="http://grgichtran.com/code/img/car-seat.jpg" class="img-polaroid" style="width: 100px;"/></a>

Exemple jsFiddle:
http://jsfiddle.net/pootie/EBLc7/
*/


(function() {
  var $, Lightbox, LightboxOptions, wasModal;
  const url = window.location.pathname;
  var theCurrentPage = url.substring(url.lastIndexOf('/')+1);
  var magnifyActive = false;

  $ = jQuery;

  LightboxOptions = (function() {
    function LightboxOptions() {
      this.fadeDuration = 500;
      this.fitImagesInViewport = true;
      this.resizeDuration = 700;
      this.showImageNumberLabel = true;
      this.wrapAround = true;
    }

    LightboxOptions.prototype.albumLabel = function(curImageNum, albumSize) {
      return "Image " + curImageNum + " de " + albumSize;
    };

    return LightboxOptions;

  })();

  Lightbox = (function() {
    function Lightbox(options) {
      this.options = options;
      this.album = [];
      this.currentImageIndex = void 0;
      this.init();
    }

    Lightbox.prototype.init = function() {
      this.enable();
      return this.build();
    };

    Lightbox.prototype.enable = function() {
      var _this = this;
      return $('body').on('click', 'a[rel^=lightbox], area[rel^=lightbox], a[data-lightbox], area[data-lightbox]', function(e) {
        _this.start($(e.currentTarget));
        return false;
      });
    };

    Lightbox.prototype.build = function() {
      var _this = this;
      $("<div id='lightboxOverlay' class='lightboxOverlay'></div><div id='lightbox' class='lightbox'><a class='lb-prev lb-nav' href='' ></a><a class='lb-next lb-nav' href='' ></a><div class='lb-outerContainer'><div class='lb-container'><img class='lb-image' src='' /><div class='lb-loader'><a class='lb-cancel'></a></div></div></div><div class='lb-dataContainer'><div class='lb-data'><div class='lb-details'><span class='lb-caption'></span><span class='lb-number'></span></div><div class='lb-closeContainer'><a class='lb-close'></a><a class='lb-rotate'></a><a class='lb-magnify'><i class='fa fa-search fa-2x'></i><a class='lb-zoomIn'><i class='fa fa-search-plus fa-2x'></i></a><a class='lb-zoomOut'><i class='fa fa-search-minus fa-2x'></i></a><a href='' class='lb-download' download><i class='fa fa-download fa-2x'></i></a></div></div></div></div>").appendTo($('body'));
      //$("<div id='lightboxOverlay' class='lightboxOverlay'></div><div id='lightbox' class='lightbox'><a class='lb-prev lb-nav' href='' ></a><a class='lb-next lb-nav' href='' ></a><div class='lb-outerContainer'><div class='lb-container'><img class='lb-image' src='' /><div class='lb-loader'><a class='lb-cancel'></a></div></div></div><div class='lb-dataContainer'><div class='lb-data'><div class='lb-details'><span class='lb-caption'></span><span class='lb-number'></span></div><div class='lb-closeContainer'><a class='lb-close'></a><a class='lb-rotate'></a><a class='lb-magnify'><i class='fa fa-search fa-2x'></i><a class='lb-zoomIn'><i class='fa fa-search-plus fa-2x'></i></a><a class='lb-zoomOut'><i class='fa fa-search-minus fa-2x'></i></a><a href='' class='lb-download' download><i class='fa fa-download fa-2x'></i></a><a class='lb-validate-camp' data-imglink=''><i class='fa fa-check-circle fa-2x'></i></a><a class='lb-validate-docs' data-imglink=''><i class='fa fa-check-circle fa-2x'></i></a></div></div></div></div>").appendTo($('body'));
      this.$lightbox = $('#lightbox');
      this.$overlay = $('#lightboxOverlay');
      this.$outerContainer = this.$lightbox.find('.lb-outerContainer');
      this.$container = this.$lightbox.find('.lb-container');
      this.containerTopPadding = parseInt(this.$container.css('padding-top'), 10);
      this.containerRightPadding = parseInt(this.$container.css('padding-right'), 10);
      this.containerBottomPadding = parseInt(this.$container.css('padding-bottom'), 10);
      this.containerLeftPadding = parseInt(this.$container.css('padding-left'), 10);
      this.$overlay.hide().on('click', function() {
        _this.end();
        return false;
      });
      this.$lightbox.hide().on('click', function(e) {
        if ($(e.target).attr('id') === 'lightbox') {
          _this.end();
        }
        return false;
      });
      this.$outerContainer.on('click', function(e) {
        if ($(e.target).attr('id') === 'lightbox') {
          _this.end();
        }
        return false;
      });
      this.$lightbox.find('.lb-prev').on('click', function() {
        if (_this.currentImageIndex === 0) {
          _this.changeImage(_this.album.length - 1);
        } else {
          _this.changeImage(_this.currentImageIndex - 1);
        }
        return false;
      });
      this.$lightbox.find('.lb-next').on('click', function() {
        if (_this.currentImageIndex === _this.album.length - 1) {
          _this.changeImage(0);
        } else {
          _this.changeImage(_this.currentImageIndex + 1);
        }
        return false;
      });
      this.$lightbox.find('.lb-rotate').on('click', function() {
          $cont = _this.$lightbox.find('.lb-outerContainer');
          $innercont = _this.$lightbox.find('.lb-container');
          $magnifier = _this.$lightbox.find('.img-magnifier-glass');
          $magnifierButton = _this.$lightbox.find('.lb-magnify');
          $image = _this.$lightbox.find('.lb-image');

            if ($($cont).attr('angle') == null) {
                $($cont).attr('angle', 0);
            }
            var value = Number($($cont).attr('angle'));
            value += 90;

            //$($cont).rotate({ animateTo:value});
			//$($cont).css("transform","rotate("+value+"deg)");
			//$($image).css("transform","rotate("+value+"deg)");
			$($magnifier).hide(0);
			$($magnifierButton).hide(0);
			if(value==360 || value==720 || value==1080 || value==1440 || value==1800 || value==2160) {
				if(magnifyActive) $($magnifier).show();
				$($magnifierButton).show();
			}
			$($innercont).css("transform","rotate("+value+"deg)");
            $($cont).attr('angle', value);
			// We're gonna resize the container: If image is 90 or 270 degrees we reverse width & height
			//if(value==90 || value==270) _this.sizeContainer($image.height(), $image.width());
			//else _this.sizeContainer($image.width(), $image.height());
			/*
			setTimeout(function() {
				magnify('zoomThis', 3);
			}, 600);
			*/
          return false;
      });
	  // Choa's buttons
      this.$lightbox.find('.lb-magnify').on('click', function() {
        if(magnifyActive) {
          $('.img-magnifier-glass').remove();
          magnifyActive = false;
        }
        else {
          magnify('zoomThis', 3);
          magnifyActive = true;
        }
        return false;
      });
      this.$lightbox.find('.lb-zoomIn').on('click', function() {
        $cont = _this.$lightbox.find('.lb-outerContainer');
        $datacont = _this.$lightbox.find('.lb-dataContainer');
        $image = _this.$lightbox.find('.lb-image');

        let currWidth = $($image).width();
        let currHeight = $($image).height();
        let ratio = currWidth/currHeight;
        let	newWidth = currWidth + 100*ratio;
        let	newHeight = currHeight + 100*ratio;
        $($image).animate({width: newWidth + "px", height: newHeight + "px"}, "slow");
        $($datacont).animate({width: newWidth + "px"}, "slow");
        $($cont).animate({width: newWidth + "px", height: newHeight + "px"}, "slow", "linear", function() { Lightbox.prototype.sizeOverlay();});
        //Lightbox.prototype.sizeOverlay();
        setTimeout(function() {
          if(magnifyActive) magnify('zoomThis', 3);
        }, 1600);
        return false;
      });
      this.$lightbox.find('.lb-zoomOut').on('click', function() {
        $cont = _this.$lightbox.find('.lb-outerContainer');
        $datacont = _this.$lightbox.find('.lb-dataContainer');
        $image = _this.$lightbox.find('.lb-image');

        let currWidth = $($image).width();
        let currHeight = $($image).height();
        let ratio = currWidth/currHeight;
        let	newWidth = currWidth - 100*ratio;
        let	newHeight = currHeight - 100*ratio;
        $($image).animate({width: newWidth + "px", height: newHeight + "px"}, "slow");
        $($datacont).animate({width: newWidth + "px"}, "slow");
        $($cont).animate({width: newWidth + "px", height: newHeight + "px"}, "slow", "linear", function() { Lightbox.prototype.sizeOverlay();});
        //Lightbox.prototype.sizeOverlay();
        setTimeout(function() {
          if(magnifyActive) magnify('zoomThis', 3);
        }, 1600);
        return false;
      });
      this.$lightbox.find('.lb-download').on('click', function() {
        let myLink = $('#lightbox .lb-download').attr('href');
        const a = $('#lightbox .lb-download').clone().appendTo("footer");
        //const a = $("<a>").attr("href", myLink).attr("download", myLink).text("temp").appendTo("footer");
        a[0].click();
        a.remove();
        //alert("IN");
      });
      this.$lightbox.find('.lb-validate-camp').on('click', function() {
        App.validPictureFromLightBox(_this.$lightbox.find('.lb-validate-camp').attr('data-imglink'));
        //App.validPictureFromLightBox(this.attr('data-imglink'));
      });
      this.$lightbox.find('.lb-validate-docs').on('click', function() {
        App.validDocumentFromLightBox(_this.$lightbox.find('.lb-validate-docs').attr('data-imglink'));
        //App.validPictureFromLightBox(this.attr('data-imglink'));
      });

      return this.$lightbox.find('.lb-loader, .lb-close').on('click', function() {
      // Choa Scroll Fix when over BS4 Modals
      if(wasModal) $('body').addClass('modal-open');
          _this.end();
          return false;
      });
    };

    Lightbox.prototype.start = function($link) {
      var $window, a, dataLightboxValue, i, imageNumber, left, top, _i, _j, _len, _len1, _ref, _ref1;
      $(window).on("resize", this.sizeOverlay);
      $('select, object, embed').css({
        visibility: "hidden"
      });
      this.$overlay.width($(document).width()).height($(document).height()).fadeIn(this.options.fadeDuration);
		// Choa Scroll Fix when over BS4 Modals
		if($('body').hasClass('modal-open')) {
			$('body').removeClass('modal-open');
			wasModal = true;
		}
		else wasModal = false;
    /*
		// Choa Campaigns & Administrative buttons only...
		switch (theCurrentPage) {
			case 'campaigns.html':
				$('.lb-closeContainer .lb-validate-camp').show();
				$('.lb-closeContainer .lb-validate-docs').hide();
      break;
			case 'administratives.html':
				$('.lb-closeContainer .lb-validate-docs').show();
				$('.lb-closeContainer .lb-validate-camp').hide();
      break;
			default:
				$('.lb-closeContainer .lb-validate-camp').hide();
				$('.lb-closeContainer .lb-validate-docs').hide();
		}
    */
      this.album = [];
      imageNumber = 0;
      dataLightboxValue = $link.attr('data-lightbox');
      if (dataLightboxValue) {
        _ref = $($link.prop("tagName") + '[data-lightbox="' + dataLightboxValue + '"]');
        for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
          a = _ref[i];
          this.album.push({
            link: $(a).attr('href'),
            title: $(a).attr('title')
          });
          if ($(a).attr('href') === $link.attr('href')) {
            imageNumber = i;
          }
        }
      } else {
        if ($link.attr('rel') === 'lightbox') {
          this.album.push({
            link: $link.attr('href'),
            title: $link.attr('title')
          });
        } else {
          _ref1 = $($link.prop("tagName") + '[rel="' + $link.attr('rel') + '"]');
          for (i = _j = 0, _len1 = _ref1.length; _j < _len1; i = ++_j) {
            a = _ref1[i];
            this.album.push({
              link: $(a).attr('href'),
              title: $(a).attr('title')
            });
            if ($(a).attr('href') === $link.attr('href')) {
              imageNumber = i;
            }
          }
        }
      }
      $window = $(window);
      top = $window.scrollTop() + $window.height() / 10;
      left = $window.scrollLeft();
      this.$lightbox.css({
        top: top + 'px',
        left: left + 'px'
      }).fadeIn(this.options.fadeDuration);
      this.changeImage(imageNumber);
    };

    Lightbox.prototype.changeImage = function(imageNumber) {
      var $image, preloader,
        _this = this;
      this.disableKeyboardNav();
      $image = this.$lightbox.find('.lb-image');
      $downloadLink = this.$lightbox.find('.lb-download');
      // Choa Download link and Scroll Fix when over BS4 Modals
      $downloadLink.attr('href', _this.album[imageNumber].link);

      this.sizeOverlay();
      this.$overlay.fadeIn(this.options.fadeDuration);
      $('.lb-loader').fadeIn('slow');
      this.$lightbox.find('.lb-image, .lb-nav, .lb-prev, .lb-next, .lb-dataContainer, .lb-numbers, .lb-caption').hide();
      this.$outerContainer.addClass('animating');
      preloader = new Image();
      preloader.onload = function() {
        var $preloader, imageHeight, imageWidth, maxImageHeight, maxImageWidth, windowHeight, windowWidth;
        $image.attr('src', _this.album[imageNumber].link);
    /*
		// Choa seenPictureFromLightBox Feature... WARNING: THIS SHOULD BE COMMENTED OUTSIDE OF RESOSIGN BACKOFFICE !!!
		const myImageName = _this.album[imageNumber].link.substring(_this.album[imageNumber].link.lastIndexOf('/')+1);
		if(theCurrentPage=="campaigns.html") {
			App.seenPictureFromLightBox(myImageName);
			_this.$lightbox.find('.lb-validate-camp').attr('data-imglink', myImageName);
		}
		if(theCurrentPage=="administratives.html") {
			_this.$lightbox.find('.lb-validate-docs').attr('data-imglink', myImageName);
		}
    */
		// Choa Start (Add Magnifier)data-magnify-src
		_this.$lightbox.find('.img-magnifier-glass').attr('id', 'img-magnifier-glass');
        _this.$lightbox.find('.lb-outerContainer').removeAttr('angle');
        _this.$lightbox.find('.lb-container').css("transform","rotate(0deg)");
		$image.attr('id', 'zoomThis');
        //$image.attr('onmousemove', 'App.magnify(this, 3)');
		// Choa End
        $preloader = $(preloader);
        $image.width(preloader.width);
        $image.height(preloader.height);
        if (_this.options.fitImagesInViewport) {
          windowWidth = $(window).width();
          windowHeight = $(window).height();
          maxImageWidth = windowWidth - _this.containerLeftPadding - _this.containerRightPadding - 20;
          maxImageHeight = windowHeight - _this.containerTopPadding - _this.containerBottomPadding - 110;
          if ((preloader.width > maxImageWidth) || (preloader.height > maxImageHeight)) {
            if ((preloader.width / maxImageWidth) > (preloader.height / maxImageHeight)) {
              imageWidth = maxImageWidth;
              imageHeight = parseInt(preloader.height / (preloader.width / imageWidth), 10);
              $image.width(imageWidth);
              $image.height(imageHeight);
            } else {
              imageHeight = maxImageHeight;
              imageWidth = parseInt(preloader.width / (preloader.height / imageHeight), 10);
              $image.width(imageWidth);
              $image.height(imageHeight);
            }
          }
        }
        return _this.sizeContainer($image.width(), $image.height());
      };
      preloader.src = this.album[imageNumber].link;
      this.currentImageIndex = imageNumber;
    };

    Lightbox.prototype.sizeOverlay = function() {
      return $('#lightboxOverlay').width($(document).width()).height($(document).height());
    };

    Lightbox.prototype.sizeContainer = function(imageWidth, imageHeight) {
      var newHeight, newWidth, oldHeight, oldWidth,
        _this = this;
      oldWidth = this.$outerContainer.outerWidth();
      oldHeight = this.$outerContainer.outerHeight();
      newWidth = imageWidth + this.containerLeftPadding + this.containerRightPadding;
      newHeight = imageHeight + this.containerTopPadding + this.containerBottomPadding;
      this.$outerContainer.animate({
        width: newWidth,
        height: newHeight
      }, this.options.resizeDuration, 'swing');
      setTimeout(function() {
        if(newWidth>640) _this.$lightbox.find('.lb-dataContainer').width(newWidth);
		else _this.$lightbox.find('.lb-dataContainer').width(640);
        _this.$lightbox.find('.lb-prevLink').height(newHeight);
        _this.$lightbox.find('.lb-nextLink').height(newHeight);
        _this.showImage();
      }, this.options.resizeDuration);
    };

    Lightbox.prototype.showImage = function() {
      this.$lightbox.find('.lb-loader').hide();
      this.$lightbox.find('.lb-image').fadeIn('slow');
      this.updateNav();
      this.updateDetails();
      this.preloadNeighboringImages();
      this.enableKeyboardNav();
	  if(magnifyActive) magnify('zoomThis', 3);
    };

    Lightbox.prototype.updateNav = function() {
      this.$lightbox.find('.lb-nav').show();
      if (this.album.length > 1) {
        if (this.options.wrapAround) {
          this.$lightbox.find('.lb-prev, .lb-next').show();
        } else {
          if (this.currentImageIndex > 0) {
            this.$lightbox.find('.lb-prev').show();
          }
          if (this.currentImageIndex < this.album.length - 1) {
            this.$lightbox.find('.lb-next').show();
          }
        }
      }
    };

    Lightbox.prototype.updateDetails = function() {
      var _this = this;
      if (typeof this.album[this.currentImageIndex].title !== 'undefined' && this.album[this.currentImageIndex].title !== "") {
        this.$lightbox.find('.lb-caption').html(this.album[this.currentImageIndex].title).fadeIn('fast');
      }
      if (this.album.length > 1 && this.options.showImageNumberLabel) {
        this.$lightbox.find('.lb-number').text(this.options.albumLabel(this.currentImageIndex + 1, this.album.length)).fadeIn('fast');
      } else {
        this.$lightbox.find('.lb-number').hide();
      }
      this.$outerContainer.removeClass('animating');
      this.$lightbox.find('.lb-dataContainer').fadeIn(this.resizeDuration, function() {
        return _this.sizeOverlay();
      });
    };

    Lightbox.prototype.preloadNeighboringImages = function() {
      var preloadNext, preloadPrev;
      if (this.album.length > this.currentImageIndex + 1) {
        preloadNext = new Image();
        preloadNext.src = this.album[this.currentImageIndex + 1].link;
      }
      if (this.currentImageIndex > 0) {
        preloadPrev = new Image();
        preloadPrev.src = this.album[this.currentImageIndex - 1].link;
      }
    };

    Lightbox.prototype.enableKeyboardNav = function() {
      $(document).on('keyup.keyboard', $.proxy(this.keyboardAction, this));
    };

    Lightbox.prototype.disableKeyboardNav = function() {
      $(document).off('.keyboard');
    };

    Lightbox.prototype.keyboardAction = function(event) {
      var KEYCODE_ESC, KEYCODE_LEFTARROW, KEYCODE_RIGHTARROW, key, keycode;
      KEYCODE_ESC = 27;
      KEYCODE_LEFTARROW = 37;
      KEYCODE_RIGHTARROW = 39;
      keycode = event.keyCode;
      key = String.fromCharCode(keycode).toLowerCase();
      if (keycode === KEYCODE_ESC || key.match(/x|o|c/)) {
        this.end();
      } else if (key === 'p' || keycode === KEYCODE_LEFTARROW) {
        if (this.currentImageIndex !== 0) {
          this.changeImage(this.currentImageIndex - 1);
        }
      } else if (key === 'n' || keycode === KEYCODE_RIGHTARROW) {
        if (this.currentImageIndex !== this.album.length - 1) {
          this.changeImage(this.currentImageIndex + 1);
        }
      }
    };

    Lightbox.prototype.end = function() {
      this.disableKeyboardNav();
      $(window).off("resize", this.sizeOverlay);
      this.$lightbox.fadeOut(this.options.fadeDuration);
      this.$overlay.fadeOut(this.options.fadeDuration);
      return $('select, object, embed').css({
        visibility: "visible"
      });
    };

    return Lightbox;

  })();

  $(function() {
    var lightbox, options;
    options = new LightboxOptions();
    return lightbox = new Lightbox(options);
  });

// Choa
function magnify(imgID, zoom) {
	var img, glass, w, h, bw;
	img = document.getElementById(imgID);

	/* Create or get magnifier glass: */
	if(magnifyActive) {
		// Get magnifier glass as it was there
		glass = document.getElementById("img-magnifier-glass");
	}
	else {
		// Set magnifier glass as it wasn't there
		glass = document.createElement("DIV");
		glass.setAttribute("class", "img-magnifier-glass");
		/* Insert magnifier glass: */
		img.parentElement.insertBefore(glass, img);
	}
	/* Set background properties for the magnifier glass: */
	glass.style.backgroundImage = "url('" + img.src + "')";
	glass.style.backgroundRepeat = "no-repeat";
	glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
	bw = 3;
	w = glass.offsetWidth / 2;
	//h = glass.offsetHeight / 2;
	h = glass.offsetHeight / 1.5;

	/* Execute a function when someone moves the magnifier glass over the image: */
	glass.addEventListener("mousemove", moveMagnifier);
	img.addEventListener("mousemove", moveMagnifier);

	/*and also for touch screens:*/
	glass.addEventListener("touchmove", moveMagnifier);
	img.addEventListener("touchmove", moveMagnifier);  
	
	function moveMagnifier(e) {
		//console.log("moveMagnifier");
		var pos, x, y;
		/* Prevent any other actions that may occur when moving over the image */
		e.preventDefault();
		/* Get the cursor's x and y positions: */
		pos = getCursorPos(e);
		/*
		// Fix position when image has rotated => we flip positions !
		const angle = Number($('.lb-outerContainer').attr('angle'));
		//if ((angle/10)%2 == 0) isEven=true; else isEven=false;
		if (isNaN(angle)) {
			x = pos.x;
			y = pos.y;
		}
		else {
			y = pos.x;
			x = pos.y;
			//console.log("reverse");
		}
		*/
		x = pos.x;
		y = pos.y;
		/* Prevent the magnifier glass from being positioned outside the image: */
		if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
		if (x < w / zoom) {x = w / zoom;}
		if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
		if (y < h / zoom) {y = h / zoom;}
		/* Set the position of the magnifier glass: */
		glass.style.left = (x - w) + "px";
		glass.style.top = (y - h) + "px";
		/* Display what the magnifier glass "sees": */
		glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
	}

	function getCursorPos(e) {
		var a, x = 0, y = 0;
		e = e || window.event;
		/* Get the x and y positions of the image: */
		a = img.getBoundingClientRect();
		/* Calculate the cursor's x and y coordinates, relative to the image: */
		x = e.pageX - a.left;
		y = e.pageY - a.top;
		/* Consider any page scrolling: */
		x = x - window.pageXOffset;
		y = y - window.pageYOffset;
		return {x : x, y : y};
	}
}

}).call(this);